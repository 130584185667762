.colouredValue {
    width: fit-content;
    padding: 3px 10px;
    border-radius: 15px;
    min-width: 50px;
    min-height: 28px;
    text-align: center;
    color: black;
}

.lowRisk {
    background-color: lightgreen;
}
.mediumRisk {
    background-color: orange;
}
.highRisk {
    background-color: lightcoral;
}
.riskNoData {
    background-color: mediumpurple;
}

.nameAddressValue {
    padding: 3px 10px;
    margin: 5px;
}

.alertType {
    color: black;
}

.alertLeftOn {
    background-color: orange;
}
.alertPreFire {
    background-color: lightcoral;
}
.alertBoiledDry {
    background-color: lightblue;
}
.alertFire {
    background-color: red;
}
