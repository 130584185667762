.amplify-button {
    text-transform: uppercase;
    font-weight: bold;
}

.loginPageContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #909090;
}

img.pippaLogo {
    margin: 10px;
    max-width: 10rem;
    max-height: 50vh;
}
